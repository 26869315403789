html, body, #root {
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 204, 255, 0.4);
  }

  70% {
    box-shadow: 0 0 0 50px rgba(0, 204, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 204, 255, 0);
  }
}